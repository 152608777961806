<ng-container [ngSwitch]="variant">
  <ng-container *ngSwitchCase="'base-logged'">
    <a
      class="connect-new-card-link"
      [attr.aria-label]="'casino.btn.connectNewAccount'"
      [fidRouterLink]="['casinos']"
    >
    </a>

    <div *transloco="let t" class="connect-new-card-details">
      <p class="connect-new-card-text">{{ t('casino.btn.connectNewAccount') }}</p>
    </div>

    <div class="connect-new-card-icon">
      <tui-svg src="fidLink"></tui-svg>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div
      class="connect-new-card-media"
      [style.width]="mediaSizeMap[size] + 'px'"
      [style.height]="mediaSizeMap[size] + 'px'"
    >
      <tui-svg src="fidLink"></tui-svg>
    </div>

    <div *transloco="let t" class="connect-new-card-details">
      <p class="connect-new-card-text">{{ t('casino.btn.connect') }}</p>
    </div>
  </ng-container>
</ng-container>
