import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CtaButtonDirective } from 'shared/directives/cta-button.directive';

@Injectable({
  providedIn: 'root',
})
export class CTARegistryService {
  private pool = new Map<CtaButtonDirective, boolean>();

  private isAnyCtaVisibleSubject$ = new BehaviorSubject<boolean>(true);

  constructor() {
    this.checkVisibility();
  }

  public observeVisibility(): Observable<boolean> {
    return this.isAnyCtaVisibleSubject$.asObservable();
  }

  public register(button: CtaButtonDirective, isVisible: boolean): void {
    this.pool.set(button, isVisible);
    this.checkVisibility();
  }

  public unregister(button: CtaButtonDirective): void {
    this.pool.delete(button);
    this.checkVisibility();
  }

  private checkVisibility(): void {
    const isAnyCtaVisible = Array.from(this.pool).some(([, isVisible]) => isVisible);
    this.isAnyCtaVisibleSubject$.next(isAnyCtaVisible);
  }
}
