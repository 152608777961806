import { Component, HostBinding, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { RouterLinkDirective } from 'core/base/directives/router-link.directive';

type Size = 'small' | 'large';
type Variant = 'base' | 'base-logged';

@Component({
  selector: 'fid-connect-new-card',
  standalone: true,
  imports: [
    TuiButtonModule,
    TuiSvgModule,
    TranslocoDirective,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    RouterLinkDirective,
  ],
  templateUrl: './connect-new-card.component.html',
  styleUrl: './connect-new-card.component.scss',
})
export class ConnectNewCardComponent {
  @Input() @HostBinding('class') public size: Size = 'small';
  @Input() @HostBinding('class') public variant: Variant = 'base';

  public readonly mediaSizeMap: Record<Size, number> = {
    small: 40,
    large: 60,
  };
}
